<template>
  <MDBModal v-model="whiteListedModal">
    <MDBModalHeader class="py-3 px-4">
      <MDBModalTitle> Whitelisted </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4">
      <template v-for="(item, index) in selectionList" :key="index">
        <MDBRadio :label="item.label" :formCheck="false" name="whitelisted" v-model="whiteListedValue"
          :value="item.value" />
      </template>
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn size="sm" color="light" @click="whiteListedModal = false">Close</MDBBtn>
      <MDBBtn size="sm" class="solid" outline="primary" @click="save">
        Save changes
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBBtn,
  MDBRadio,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-vue-ui-kit";
import { useCopiesFilter } from "../store/copiesFilter";
import { storeToRefs } from "pinia";
import { watch, ref } from "vue";

const copiesFilterStore = useCopiesFilter();

const {
  whiteListedModal,
  whiteListedValue,
  whiteListedValueName,
  confirmedWhiteListedValue,
} = storeToRefs(copiesFilterStore);

const selectionList = ref([
  {
    label: "All Whitelists",
    value: "0",
  },
  {
    label: "Show Only",
    value: "1",
  },
  {
    label: "Exclude",
    value: "2",
  },
]);

const save = () => {
  confirmedWhiteListedValue.value = whiteListedValue.value;
  const findCollection = selectionList.value.find(
    (list) => list.value === whiteListedValue.value
  );
  whiteListedValueName.value = findCollection.label;

  whiteListedModal.value = false;
  whiteListedValue.value = "0";
};

watch(whiteListedModal, (newVal) => {
  if (newVal) {
    whiteListedValue.value = confirmedWhiteListedValue.value;
  }
});
</script>

<style scoped>
.solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.solid:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}
</style>
