import { HttpClient } from "../HttpClient";
/**
 * * Patch Copies Status BulkUpdate
 */
export const PatchCopiesStatusBulkUpdate = async ({
  ids,
  status,
}) => {
  try {
    const { data } = await HttpClient.patch(`api/pexcopies/Status`, {
      StatusId: status,
      ...(ids ? { PexCopyIds: ids } : {}),
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
