import { HttpClient } from "../HttpClient";
/**
 * * Post Export Copies
 */
export const PostExportCopies = async ({ assetId, lastItem, filters }) => {
  try {
    const { data } = await HttpClient.post(
      "api/pexcopies/ExportToExcel",
      {
        ...(assetId ? { assetId: assetId } : {}),
        ...(lastItem ? { maxId: lastItem } : {}),
        ...(filters ? { ...filters } : {})
      },
      {
        responseType: "blob",
      }
    );
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
