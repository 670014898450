import { HttpClient } from "../HttpClient";
/**
 * * Get Copies
 */
export const GetCopies = async ({ assetId, lastItem, filters }) => {
    try {
        const { data } = await HttpClient.post("api/pexcopies/list", {
            ...(assetId ? { assetId: assetId } : {}),
            ...(lastItem ? { maxId: lastItem } : {}),
            ...(filters ? { ...filters } : {}),
            count: 10,
        });
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};
