import { HttpClient } from "../HttpClient";
/**
 * * Get Copies Total Count
 */
export const GetCopiesTotalCount = async ({ filters }) => {
  try {
    const { data } = await HttpClient.post("api/pexcopies/totalCount", {
      ...(filters ? { ...filters } : {}),
      count: 10,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
