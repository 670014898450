<template>
  <MDBSideNav v-model="copiesFilter" id="copiesFilter" mode="over" contentSelector="#copyright-body" scrollContainer>
    <MDBSideNavMenu>
      <MDBSideNavItem class="copies-nav-header"> SOURCE </MDBSideNavItem>
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Asset </MDBSideNavLink>
      </MDBSideNavItem> -->
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': selectedCollectionName }"
          @click="collectionModalOpen = !collectionModalOpen">
          Collection
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="selectedCollectionName" class="active-filter">
        <span>{{ selectedCollectionName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetCollection" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem class="copies-nav-header"> COPY STATUS </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink @click="platformModalOpen = !platformModalOpen">
          Platform
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="selectedPlatformNameList.length > 0" class="active-filter">
        <span class="platform">{{ parseSelectedPlatform }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetPlatformList" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink @click="viewsModal = !viewsModal">
          Views
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="confirmedView.minValue" class="active-filter">
        <span>{{ parseViewCount }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetViewsMinValue" class="cursor-pointer" />
      </MDBSideNavItem>
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Engagement </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Duration </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Date Uploaded </MDBSideNavLink>
      </MDBSideNavItem> -->
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': verifiedUserValueName }"
          @click="verifiedUserModal = !verifiedUserModal">
          Verified User
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="verifiedUserValueName" class="active-filter">
        <span>{{ verifiedUserValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetVerifiedUser" class="cursor-pointer" />
      </MDBSideNavItem>
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> User/Channel </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Claimed </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Takedown </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem class="copies-nav-header"> MATCH FILTERS </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Match Type </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Length of match </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Copy duration (% of length) </MDBSideNavLink>
      </MDBSideNavItem> -->
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Asset duration (% of length) </MDBSideNavLink>
      </MDBSideNavItem> -->
      <MDBSideNavItem class="copies-nav-header">
        PROCESSED STATUS
      </MDBSideNavItem>
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Date Processed </MDBSideNavLink>
      </MDBSideNavItem> -->
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': claimIssuedValueName }"
          @click="claimIssueModal = !claimIssueModal">
          Claim Issued
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="claimIssuedValueName" class="active-filter">
        <span>{{ claimIssuedValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetClaimIssue" class="cursor-pointer" />
      </MDBSideNavItem>
      <!-- <MDBSideNavItem>
        <MDBSideNavLink> Takedown issued </MDBSideNavLink>
      </MDBSideNavItem> -->
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': whiteListedValueName }"
          @click="whiteListedModal = !whiteListedModal">
          Whitelisted
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="whiteListedValueName" class="active-filter">
        <span>{{ whiteListedValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetWhitelisted" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': fairUseValueName }" @click="fairUseModal = !fairUseModal">
          Fair Use
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="fairUseValueName" class="active-filter">
        <span>{{ fairUseValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetFairUse" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': forFurtherReviewValueName }"
          @click="forFurtherReviewModal = !forFurtherReviewModal">
          For Further Review
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="forFurtherReviewValueName" class="active-filter">
        <span>{{ forFurtherReviewValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetForFurtherReview" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': externallyActionedValueName }"
          @click="externallyActionedModal = !externallyActionedModal">
          Externally Actioned
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="externallyActionedValueName" class="active-filter">
        <span>{{ externallyActionedValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetExternallyActioned" class="cursor-pointer" />
      </MDBSideNavItem>
      <MDBSideNavItem>
        <MDBSideNavLink :class="{ 'has-active-filter': falsePositiveValueName }"
          @click="falsePositiveModal = !falsePositiveModal">
          Labeled as False Positive
        </MDBSideNavLink>
      </MDBSideNavItem>
      <MDBSideNavItem v-if="falsePositiveValueName" class="active-filter">
        <span>{{ falsePositiveValueName }}</span>
        <MDBIcon icon="times-circle" size="xs" @click="resetFalsePositive" class="cursor-pointer" />
      </MDBSideNavItem>
    </MDBSideNavMenu>
  </MDBSideNav>
</template>

<script>
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBIcon,
} from "mdb-vue-ui-kit";
import { watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useCopiesFilter } from "../store/copiesFilter";

export default {
  components: {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBIcon,
  },
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const copiesFilter = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });
    watch(copiesFilter, (newFilter) => {
      if (!newFilter) userCopiesStore.closeFilter();
    });
    const userCopiesStore = useCopiesFilter();
    const {
      platformModalOpen,
      collectionModalOpen,
      viewsModal,
      viewsIsMoreThan,
      viewsIsLessThan,
      viewsIsRange,
      viewsMinValue,
      viewsMaxValue,
      confirmedView,
      verifiedUserModal,
      claimIssueModal,
      fairUseModal,
      forFurtherReviewModal,
      externallyActionedModal,
      falsePositiveModal,
      whiteListedModal,
      selectedCollectionName,
      confirmedCollection,
      selectedPlatformList,
      selectedPlatformNameList,
      verifiedUserValueName,
      confirmedVerifiedUserValue,
      claimIssuedValueName,
      confirmedClaimIssuedValue,
      whiteListedValueName,
      confirmedWhiteListedValue,
      fairUseValueName,
      confirmedFairUseValue,
      forFurtherReviewValueName,
      confirmedForFurtherReviewValue,
      externallyActionedValueName,
      confirmedExternallyActionedValue,
      falsePositiveValueName,
      confirmedFalsePositiveValue,
    } = storeToRefs(userCopiesStore);
    const resetCollection = () => {
      selectedCollectionName.value = "";
      confirmedCollection.value = null;
    };
    const resetViewsMinValue = () => {
      confirmedView.value = {
        minValue: null,
        maxValue: null,
        isMoreThan: true,
        isLessThan: false,
        isRange: false,
      };
      viewsIsMoreThan.value = true;
      viewsIsLessThan.value = false;
      viewsIsRange.value = false;
      viewsMinValue.value = null;
      viewsMaxValue.value = null;
    };
    const parseViewCount = computed(() => {
      if (confirmedView.value.isLessThan) {
        return `Less than ${confirmedView.value.minValue}`;
      }
      if (confirmedView.value.isRange) {
        return `${confirmedView.value.minValue} � ${confirmedView.value.maxValue}`;
      }
      return `More than ${confirmedView.value.minValue}`;
    });
    const resetVerifiedUser = () => {
      verifiedUserValueName.value = "";
      confirmedVerifiedUserValue.value = "0";
    };
    const resetClaimIssue = () => {
      claimIssuedValueName.value = "";
      confirmedClaimIssuedValue.value = "0";
    };
    const resetWhitelisted = () => {
      whiteListedValueName.value = "";
      confirmedWhiteListedValue.value = "0";
    };
    const resetFairUse = () => {
      fairUseValueName.value = "";
      confirmedFairUseValue.value = "0";
    };
    const resetForFurtherReview = () => {
      forFurtherReviewValueName.value = "";
      confirmedForFurtherReviewValue.value = "0";
    };
    const resetExternallyActioned = () => {
      externallyActionedValueName.value = "";
      confirmedExternallyActionedValue.value = "0";
    };
    const resetFalsePositive = () => {
      falsePositiveValueName.value = "";
      confirmedFalsePositiveValue.value = "0";
    };
    const parseSelectedPlatform = computed(() =>
      selectedPlatformNameList.value.join(", ")
    );
    const resetPlatformList = () => {
      selectedPlatformList.value = [];
      selectedPlatformNameList.value = [];
    };
    return {
      copiesFilter,
      platformModalOpen,
      collectionModalOpen,
      viewsModal,
      verifiedUserModal,
      claimIssueModal,
      fairUseModal,
      forFurtherReviewModal,
      externallyActionedModal,
      falsePositiveModal,
      whiteListedModal,
      selectedCollectionName,
      verifiedUserValueName,
      claimIssuedValueName,
      whiteListedValueName,
      fairUseValueName,
      forFurtherReviewValueName,
      externallyActionedValueName,
      falsePositiveValueName,
      selectedPlatformNameList,
      parseSelectedPlatform,
      confirmedView,
      parseViewCount,
      resetCollection,
      resetViewsMinValue,
      resetVerifiedUser,
      resetClaimIssue,
      resetWhitelisted,
      resetFairUse,
      resetForFurtherReview,
      resetExternallyActioned,
      resetFalsePositive,
      resetPlatformList,
    };
  },
};
</script>

<style scoped>
#copiesFilter {
  border-right: 1px solid gray;
  transition: unset;
}

#copiesFilter .sidenav-menu {
  padding: 0;
}

#copiesFilter .copies-nav-header {
  padding: 1rem 1.5rem;
  font-weight: bold;
  background-color: var(--primary);
  color: white;
}

#copiesFilter .sidenav-link {
  border-radius: 0;
}

#copiesFilter .sidenav-link:hover {
  color: white;
  background-color: var(--accent);
}

#copiesFilter .cursor-pointer {
  cursor: pointer;
  color: var(--primary);
}

#copiesFilter .has-active-filter {
  color: var(--accent);
  font-weight: bold;
}

#copiesFilter .active-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  background-color: var(--accent);
  color: white;
}

#copiesFilter .platform {
  word-break: break-word;
}
</style>
