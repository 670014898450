import { HttpClient } from "../HttpClient";
/**
 * * Patch Copies Status Bulk Update using Filter
 */
export const PatchCopiesStatusBulkUpdateFilter = async ({
  status,
  filtersSet,
}) => {
  try {
    const { data } = await HttpClient.patch(`api/pexcopies/Status/All`, {
      Status: status,
      ...(filtersSet ? { ...filtersSet } : {}),
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
