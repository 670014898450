<template>
    <LayoutNew>
        <h1 class="text-center">Copies</h1>
        <CopiesComponentFilter v-model="copiesFilter" />
        <CopiesComponentFiltersModals />
        <!-- button group -->
        <MDBCard id="CopiesComponent" fluid>
            <MDBCardHeader class="p-4 overflow-auto">
                <MDBBtnGroup class="actions mt-2" aria-label="Actions">
                    <MDBBtn class="solid" outline="primary" @click="copiesFilter = !copiesFilter">
                        <MDBIcon icon="filter" class="me-2"></MDBIcon>Manage Filters
                    </MDBBtn>
                    <MDBBtn class="outlined" outline="tertiary" @click="createCollectionModal = true">
                        Create Collection
                    </MDBBtn>
                    <MDBBtn class="outlined" outline="tertiary" @click="goToAsset">
                        Add Asset(s)
                    </MDBBtn>
                    <MDBBtn class="outlined" outline="tertiary" :disabled="!isBulkActionEnable"
                        @click="bulkActionModal = true">
                        Bulk Action
                    </MDBBtn>
                    <MDBBtn class="outlined" outline="tertiary" @click="downloadModal = true">
                        Download
                    </MDBBtn>
                </MDBBtnGroup>
                <h6 v-if="copiesTableRef" class="mt-4">
                    About
                    <strong>{{ copiesTableRef?.copyListTotalCount }}</strong>
                    Copies
                </h6>
            </MDBCardHeader>
            <MDBCardBody class="overflow-auto p-4">
                <p v-if="isBulkActionEnable" class="mb-3">
                    {{ copiesText }}
                </p>
                <CopiesComponentTable ref="copiesTableRef" />
            </MDBCardBody>
        </MDBCard>
        <!-- bulk actions modal -->
        <MDBModal tag="form" id="bulkActionModal" tabindex="-1" labelledby="bulkActionModalLabel"
            v-model="bulkActionModal">
            <MDBModalHeader class="px-4 py-3">
                <MDBModalTitle id="bulkActionModalLabel"> Bulk Actions </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBBtn color="primary" @click="markAsExternallyAction">
                    <MDBSpinner v-if="bulkActionLoading" size="sm" />
                    <template v-else> <span>Mark as Externally Actioned</span> </template>
                </MDBBtn>
            </MDBModalBody>
            <MDBModalFooter class="py-3">
                <MDBBtn size="sm" color="tertiary" @click="bulkActionModal = false">Close</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <!-- toast -->
        <MDBToast v-model="bulkActionToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Selected Copies Updated Successfully
        </MDBToast>
        <!-- create collection modal -->
        <MDBModal tag="form" id="createCollectionModal" tabindex="-1" labelledby="CreateCollectionLabel"
            v-model="createCollectionModal">
            <MDBModalHeader class="py-3 px-4">
                <MDBModalTitle id="CreateCollectionLabel">
                    Create Collection
                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="p-4 pt-3">
                <p>You can manually add Assets to a Collection</p>
                <MDBInput class="mb-2" placeholder="Enter Collection Name *" type="text" v-model="collectionName"
                    required invalidFeedback="Please provide your first name" />
                <MDBInput placeholder="Enter Reference ID" type="text" v-model="referenceId" />
            </MDBModalBody>
            <MDBModalFooter class="py-3 gap-2">
                <MDBBtn size="sm" color="tertiary" @click="createCollectionModal = false">
                    Close
                </MDBBtn>
                <MDBBtn size="sm" type="button" color="primary" :disabled="collectionName == ''"
                    @click.prevent.stop="createCollection">
                    <MDBSpinner v-if="createCollectionModalLoader" size="sm" />
                    <span v-else>Create Collection</span>
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <!-- toast -->
        <MDBToast v-model="collectionToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Collection Created Successfully
        </MDBToast>
        <!-- download modal -->
        <MDBModal tag="form" id="downloadModal" tabindex="-1" labelledby="downloadModalLabel" v-model="downloadModal">
            <MDBModalHeader class="px-4 py-3">
                <MDBModalTitle id="downloadModalLabel"> Download </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <p class="m-auto">
                    Download all
                    <strong>{{ copiesTableRef?.copyListTotalCount }}</strong> Copies?
                </p>
            </MDBModalBody>
            <MDBModalFooter class="py-3 gap-2">
                <MDBBtn size="sm" color="tertiary" @click="downloadModal = false"> Close </MDBBtn>
                <MDBBtn size="sm" type="button" color="primary" @click.prevent.stop="download">
                    <MDBSpinner v-if="downloadModalLoader" size="sm" />
                    <span v-else>Download</span>
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        <!-- toast -->
        <MDBToast v-model="downloadToast" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            {{ copiesTableRef?.copyListTotalCount }} Downloaded Successfully
        </MDBToast>
    </LayoutNew>
</template>

<script setup>
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBBtn,
    MDBIcon,
    MDBBtnGroup,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBToast,
    MDBSpinner,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import CopiesComponentFilter from "@/components/CopiesComponentFilter.vue";
import CopiesComponentTable from "@/components/CopiesComponentTable.vue";
import CopiesComponentFiltersModals from "@/components/CopiesComponentFiltersModals.vue";
import { PostCollection } from "@/services/Collections/PostCollection";
import { PatchCopiesStatusBulkUpdate } from "@/services/Copies/PatchCopiesStatusBulkUpdate";
import { PatchCopiesStatusBulkUpdateFilter } from "@/services/Copies/PatchCopiesStatusBulkUpdateFilter";
import { useCopiesFilter } from "@/store/copiesFilter";
import { storeToRefs } from "pinia";
import { PostExportCopies } from "@/services/Copies/PostExportCopies";
import { useTitle } from "@vueuse/core";

useTitle("Copyright - Copies | CreatorShield");

const copiesFilterStore = useCopiesFilter();
const { copiesFilter, filtersSet } = storeToRefs(copiesFilterStore);

const collectionToast = ref(false);
const createCollectionModal = ref(false);
const createCollectionModalLoader = ref(false);
const collectionName = ref("");
const referenceId = ref("");
const createCollection = async () => {
    const formBody = {
        title: collectionName.value,
        referenceId: referenceId.value,
    };
    createCollectionModalLoader.value = true;
    await PostCollection(formBody);
    createCollectionModalLoader.value = false;
    createCollectionModal.value = false;
    collectionToast.value = true;
};
watch(createCollectionModal, () => {
    collectionName.value = "";
    referenceId.value = "";
});
const router = useRouter();
const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
};
const copiesTableRef = ref();
const isBulkActionEnable = computed(() => {
    return (
        (copiesTableRef.value && copiesTableRef.value?.selectAll) ||
        copiesTableRef.value?.selectedList.length > 0
    );
});
const bulkActionModal = ref(false);
const bulkActionLoading = ref(false);
const bulkActionToast = ref(false);
const markAsExternallyAction = async () => {
    try {
        bulkActionLoading.value = true;

        if (!copiesTableRef.value?.selectAll) {
            const selectedList = copiesTableRef.value?.selectedList;
            const selectedListIds = selectedList.map((list) => {
                return list.matchIdString;
            });
            bulkActionLoading.value = true;
            await PatchCopiesStatusBulkUpdate({
                ids: selectedListIds,
                status: 30,
            });
        } else {
            await PatchCopiesStatusBulkUpdateFilter({
                filtersSet: { ...filtersSet.value },
                status: 30,
            });
        }
    } catch (error) {
        console.error("Mark as Externally Action Err: ", error);
    } finally {
        bulkActionLoading.value = false;
        bulkActionModal.value = false;
        bulkActionToast.value = true;
        copiesTableRef.value?.setInitialCopies();
    }
};
const downloadModal = ref(false);
const downloadModalLoader = ref(false);
const downloadToast = ref(false);
const download = async () => {
    downloadModalLoader.value = true;
    const params = {
        ...(filtersSet.value ? { filters: { ...filtersSet.value } } : {}),
    };
    const response = await PostExportCopies(params);
    const fileURL = window.URL.createObjectURL(
        new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    );
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
        "download",
        `CreatorShield-Copies-${new Date(new Date()).toISOString().split("T")[0]
        }.csv`
    );
    document.body.appendChild(fileLink);
    fileLink.click();
    downloadModalLoader.value = false;
    downloadModal.value = false;
    downloadToast.value = true;
};
const copiesText = computed(() => {
    const copiesNumber = copiesTableRef.value?.selectAll
        ? copiesTableRef.value?.copyListTotalCount -
        copiesTableRef.value?.unSelectedList.length
        : copiesTableRef.value?.selectedList.length;

    return `${copiesNumber} copies selected.`;
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

#CopiesComponent .title {
    margin: 0;
    color: var(--accent);
}

#CopiesComponent .actions {
    margin-right: auto;
}

#CopiesComponent .actions .solid {
    background-color: var(--accent);
    border-color: var(--accent);
    color: white;
}

#CopiesComponent .actions .solid:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

#CopiesComponent .actions .outlined {
    color: var(--accent);
    border-color: var(--accent);
}

#CopiesComponent .actions .outlined:hover {
    background-color: var(--primary);
    color: white;
}
</style>