import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useCopiesFilter = defineStore("copiesFilter", () => {
  // copiesFilter
  const copiesFilter = ref(false);
  // platform
  const platformModalOpen = ref(false);
  const platformListDefault = ref([
    { text: "Apple Podcasts", value: 1, isSelected: false },
    { text: "Audiomack", value: 2, isSelected: false },
    { text: "Audius", value: 3, isSelected: false },
    { text: "Dailymotion", value: 4, isSelected: false },
    { text: "Datpiff", value: 5, isSelected: false },
    { text: "Facebook", value: 6, isSelected: false },
    { text: "Gfycat", value: 7, isSelected: false },
    { text: "Giphy", value: 8, isSelected: false },
    { text: "Imgur", value: 9, isSelected: false },
    { text: "Instagram", value: 10, isSelected: false },
    { text: "MixCloud", value: 11, isSelected: false },
    { text: "My Mixtapez", value: 12, isSelected: false },
    { text: "Oddshot", value: 13, isSelected: false },
    { text: "OK", value: 14, isSelected: false },
    { text: "Pearvideo", value: 15, isSelected: false },
    { text: "Reddit", value: 16, isSelected: false },
    { text: "Soundcloud", value: 17, isSelected: false },
    { text: "Streamable", value: 18, isSelected: false },
    { text: "Spotify Podcasts", value: 19, isSelected: false },
    { text: "Tenor", value: 20, isSelected: false },
    { text: "Tiktok", value: 21, isSelected: false },
    { text: "Triller", value: 22, isSelected: false },
    { text: "Twitch", value: 23, isSelected: false },
    { text: "Twitch Clips", value: 24, isSelected: false },
    { text: "Twitter", value: 25, isSelected: false },
    { text: "Vimeo", value: 26, isSelected: false },
    { text: "VK", value: 27, isSelected: false },
    { text: "Youku", value: 28, isSelected: false },
    { text: "Youtube", value: 29, isSelected: false },
  ]);
  const platformList = ref([...platformListDefault.value]);
  const selectedPlatformList = ref([]);
  const selectedPlatformNameList = ref([]);
  // Views
  const viewsModal = ref(false);
  const viewsIsMoreThan = ref(true);
  const viewsIsLessThan = ref(false);
  const viewsIsRange = ref(false);
  const viewsMinValue = ref(null);
  const viewsMaxValue = ref(null);
  const confirmedView = ref({
    isMoreThan: true,
    isLessThan: false,
    isRange: false,
    minValue: null,
    maxValue: null,
  });
  // collection
  const collectionModalOpen = ref(false);
  const collectionList = ref([]);
  const selectedCollection = ref();
  const selectedCollectionName = ref("");
  const confirmedCollection = ref(null);
  // verified user
  const verifiedUserModal = ref(false);
  const verifiedUserValue = ref("0");
  const verifiedUserValueName = ref("");
  const confirmedVerifiedUserValue = ref("0");
  // claim issue
  const claimIssueModal = ref(false);
  const claimIssuedValue = ref("0");
  const claimIssuedValueName = ref("");
  const confirmedClaimIssuedValue = ref("0");
  // whitelisted
  const whiteListedModal = ref(false);
  const whiteListedValue = ref("0");
  const whiteListedValueName = ref("");
  const confirmedWhiteListedValue = ref("0");
  // fair use
  const fairUseModal = ref(false);
  const fairUseValue = ref("0");
  const fairUseValueName = ref("");
  const confirmedFairUseValue = ref("0");
  // for further review
  const forFurtherReviewModal = ref(false);
  const forFurtherReviewValue = ref("0");
  const forFurtherReviewValueName = ref("");
  const confirmedForFurtherReviewValue = ref("0");
  // externally actioned
  const externallyActionedModal = ref(false);
  const externallyActionedValue = ref("0");
  const externallyActionedValueName = ref("");
  const confirmedExternallyActionedValue = ref("0");
  // false positive
  const falsePositiveModal = ref(false);
  const falsePositiveValue = ref("0");
  const falsePositiveValueName = ref("");
  const confirmedFalsePositiveValue = ref("0");
  // filter compilation
  const filtersSet = computed(() => {
    return {
      ...(selectedPlatformList.value && selectedPlatformList.value.length > 0
        ? { platformIds: selectedPlatformList.value }
        : {}),
      ...(confirmedView.value.minValue
        ? {
            views: {
              minValue: Number(confirmedView.value.minValue),
              maxValue: Number(confirmedView.value.maxValue),
              isMoreThan: confirmedView.value.isMoreThan,
              isLessThan: confirmedView.value.isLessThan,
              isRange: confirmedView.value.isRange,
            },
          }
        : {}),
      ...(confirmedCollection.value
        ? { collectionId: confirmedCollection.value }
        : {}),
      ...(confirmedVerifiedUserValue.value != "0"
        ? { isExcludeVerifiedUser: confirmedVerifiedUserValue.value === "2" }
        : {}),
      ...(confirmedClaimIssuedValue.value != "0"
        ? { isExcludeClaimIssued: confirmedClaimIssuedValue.value === "2" }
        : {}),
      ...(confirmedFairUseValue.value != "0"
        ? { isFairUseExcluded: confirmedFairUseValue.value === "2" }
        : {}),
      ...(confirmedForFurtherReviewValue.value != "0"
        ? {
            isForFurtherReviewExcluded:
              confirmedForFurtherReviewValue.value === "2",
          }
        : {}),
      ...(confirmedExternallyActionedValue.value != "0"
        ? {
            isExternallyActionedExcluded:
              confirmedExternallyActionedValue.value === "2",
          }
        : {}),
      ...(confirmedFalsePositiveValue.value != "0"
        ? {
            isLabeledAsFalsePositiveExcluded:
              confirmedFalsePositiveValue.value === "2",
          }
        : {}),
      ...(confirmedWhiteListedValue.value != "0"
        ? {
            whitelist: {
              excludeWhitelist: confirmedWhiteListedValue.value === "2",
              whitelistIds: [],
            },
          }
        : {}),
    };
  });
  const closeFilter = () => {
    platformModalOpen.value = false;
    collectionModalOpen.value = false;
    selectedCollection.value = null;
  };
  return {
    copiesFilter,
    platformModalOpen,
    platformListDefault,
    platformList,
    selectedPlatformList,
    selectedPlatformNameList,
    viewsModal,
    viewsIsMoreThan,
    viewsIsLessThan,
    viewsIsRange,
    viewsMinValue,
    viewsMaxValue,
    confirmedView,
    collectionModalOpen,
    collectionList,
    selectedCollection,
    selectedCollectionName,
    confirmedCollection,
    verifiedUserModal,
    verifiedUserValue,
    verifiedUserValueName,
    confirmedVerifiedUserValue,
    claimIssueModal,
    claimIssuedValue,
    claimIssuedValueName,
    confirmedClaimIssuedValue,
    whiteListedModal,
    whiteListedValue,
    whiteListedValueName,
    confirmedWhiteListedValue,
    fairUseModal,
    fairUseValue,
    fairUseValueName,
    confirmedFairUseValue,
    forFurtherReviewModal,
    forFurtherReviewValue,
    forFurtherReviewValueName,
    confirmedForFurtherReviewValue,
    externallyActionedModal,
    externallyActionedValue,
    externallyActionedValueName,
    confirmedExternallyActionedValue,
    falsePositiveModal,
    falsePositiveValue,
    falsePositiveValueName,
    confirmedFalsePositiveValue,
    filtersSet,
    closeFilter,
  };
});
